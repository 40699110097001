define("discourse/plugins/discourse-ai/initializers/ai-image-caption", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/api", "discourse-i18n"], function (_exports, _ajax, _ajaxError, _api, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.25.0", api => {
    const buttonAttrs = {
      label: _discourseI18n.default.t("discourse_ai.ai_helper.image_caption.button_label"),
      icon: "discourse-sparkles",
      class: "generate-caption"
    };
    const imageCaptionPopup = api.container.lookup("service:imageCaptionPopup");
    const settings = api.container.lookup("service:site-settings");
    if (!settings.ai_helper_enabled_features.includes("image_caption")) {
      return;
    }
    api.addComposerImageWrapperButton(buttonAttrs.label, buttonAttrs.class, buttonAttrs.icon, event => {
      if (event.target.classList.contains("generate-caption")) {
        const buttonWrapper = event.target.closest(".button-wrapper");
        const imageIndex = parseInt(buttonWrapper.getAttribute("data-image-index"), 10);
        const imageSrc = event.target.closest(".image-wrapper").querySelector("img").getAttribute("src");
        imageCaptionPopup.loading = true;
        imageCaptionPopup.showPopup = !imageCaptionPopup.showPopup;
        (0, _ajax.ajax)(`/discourse-ai/ai-helper/caption_image`, {
          method: "POST",
          data: {
            image_url: imageSrc
          }
        }).then(_ref => {
          let {
            caption
          } = _ref;
          event.target.classList.add("disabled");
          imageCaptionPopup.imageSrc = imageSrc;
          imageCaptionPopup.imageIndex = imageIndex;
          imageCaptionPopup.newCaption = caption;
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          imageCaptionPopup.loading = false;
          event.target.classList.remove("disabled");
        });
      }
    });
  });
});